<template>
  <div class="ReportCom" v-loading="loading">
    <div v-for="item in comList" :key="item.id" class="ReportCom_item">
      <h2>{{ item.title }}（{{ item.fileName }}）</h2>
      <!-- 表格样式 -->
      <template v-if="item.type === 'table'">
        <CommonTable
          :ref="'captureRefs' + item.id"
          :columns="item.columns"
          :table-data="item.data"
          :showIndex="true"
        >
        </CommonTable>
      </template>
      <template v-if="item.type === 'lineChart'">
        <div
          :id="'picture' + item.id"
          class="echartsStyle4"
          :ref="'captureRefs' + item.id"
        ></div>
      </template>
      <template v-if="item.type === 'circularChart'">
        <div
          :id="'picture' + item.id"
          class="echartsStyle4"
          :ref="'captureRefs' + item.id"
        ></div>
      </template>
      <template v-if="item.type === 'colChart'">
        <div
          :id="'picture' + item.id"
          class="echartsStyle4"
          :ref="'captureRefs' + item.id"
        ></div>
      </template>
      <template v-if="item.type === 'TotalCom'">
        <TotalCom :ref="'captureRefs' + item.id" :year="year.toString()">
        </TotalCom>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import CommonTable from "./components/CommonTable.vue";
import TotalCom from "./components/TotalCom.vue";
import { PushTaskList } from "@/api/roleManagement";
import { GetQuoteGraph, GetCitationGraph } from "@/api/analysis";
import {
  generateAnnualReport2,
  GetCountRootMagOrg,
  GetCountMagCitations,
  GetCountRootArticleCitations
} from "@/api/emailPush";
import { GetHistoryPushSetList } from "@/api/roleManagement";
import {
  column8,
  column13,
  lineChart24,
  circularChart34,
  colChart22,
  column41
} from "./js/configuration.js";

export default {
  name: "ReportCom",
  components: {
    CommonTable,
    TotalCom
  },
  props: {
    year: null
  },
  watch: {
    year: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.initData();
        }
      },
      deep: true
    },
    magId(nv) {
      if (nv) {
        this.initData();
      }
    }
  },
  data() {
    return {
      // 组件列表
      comList: [
        {
          id: 8,
          title: "图8",
          type: "table",
          fileName: "report_image_3_1_1_1",
          columns: column8,
          data: []
        },
        {
          id: 9,
          title: "图9",
          type: "table",
          fileName: "report_image_3_1_2_1",
          columns: column8,
          data: []
        },
        {
          id: 10,
          title: "图10",
          type: "table",
          fileName: "report_image_3_1_3_1",
          columns: column8,
          data: []
        },
        {
          id: 13,
          title: "图13",
          type: "table",
          fileName: "report_image_3_3_1",
          columns: column13,
          data: []
        },
        {
          id: 24,
          title: "图24",
          type: "lineChart",
          fileName: "report_image_3_7_1",
          data: []
        },
        {
          id: 34,
          title: "图34",
          type: "circularChart",
          fileName: "report_image_6_3"
        },
        {
          id: 35,
          title: "图35",
          type: "circularChart",
          fileName: "report_image_6_4"
        },
        {
          id: 22,
          title: "图22",
          type: "colChart",
          fileName: "report_image_3_6_3_1"
        },
        {
          id: 23,
          title: "图23",
          type: "colChart",
          fileName: "report_image_3_6_4_1"
        },
        {
          id: 41,
          title: "图41",
          type: "table",
          columns: column41,
          fileName: "report_image_6_9",
          data: []
        },
        {
          id: 7,
          title: "图7",
          type: "TotalCom",
          fileName: "report_image_2_5_1"
        }
      ],
      typeList: [
        { key: "整期", value: 1 },
        { key: "专题", value: 2 },
        { key: "单篇", value: 4 }
      ],
      loading: true,
      firstData: []
    };
  },
  computed: {
    ...mapGetters({
      magId: "magId",
      platMagName: "platMagName"
    }),
    tableColumn() {
      return column8;
    }
  },
  created() {
    // this.initData();
  },
  mounted() {},
  methods: {
    yearFormat(year) {
      return {
        startPushTime: year + "-01-01 00:00:00",
        endPushTime: year + "-12-31 00:00:00"
      };
    },
    // 获取最大的邮件打开率
    getMaxEmailOpenPercent(arr) {
      // 初始化一个变量，用于存储最大值对应的对象
      let maxItem = null;
      // 遍历数组，找到最大值
      arr.forEach(item => {
        // 将百分数字符串转换为数字，去掉百分号并转为浮动数字
        const emailOpenPercent = parseFloat(item.emailOpenPercent || 0);
        // 如果 maxItem 为空或当前的 emailOpenPercent 比 maxItem 的值大，则更新 maxItem
        if (
          !maxItem ||
          emailOpenPercent > parseFloat(maxItem.emailOpenPercent)
        ) {
          maxItem = item;
        }
      });
      return maxItem;
    },
    initData() {
      this.loading = true;
      Promise.allSettled([
        this.getTableList(this.typeList[0].value, 8),
        this.getTableList(this.typeList[1].value, 9),
        this.getTableList(this.typeList[2].value, 10),
        this.getTableList(),
        this.getData("picture24"),
        this.getGetQuoteGraph("picture34"),
        this.getGetCitationGraph("picture35"),
        this.getCountRootMagOrg(22, GetCountRootMagOrg, "picture22"),
        this.getCountRootMagOrg(23, GetCountMagCitations, "picture23"),
        this.getCountRootMagOrg(41, GetCountRootArticleCitations, "picture41")
      ]).then(res => {
        this.loading = false;
        this.$emit("reportComLoad");
      });
    },
    async captureAndUpload() {
      // 获取所有表格元素的截图并上传
      const elementList = this.comList
        .map(item => {
          const $ref = this.$refs["captureRefs" + item.id]?.[0] || null;
          return {
            ref: $ref,
            ...item
          };
        })
        .filter(item => item.ref);
      console.log(elementList, "elementList");
      const promises = elementList.map(item => {
        if (!item.ref) return Promise.resolve(); // 如果没有 ref，返回一个已解决的 Promise
        return this.captureAndUploadSingle(item.ref.$el || item.ref, item);
      });
      Promise.all(promises)
        .then(files => {
          // 过滤掉空值
          let fileArr = files.filter(item => item);
          // 在这里调用你想要在所有图片生产完成后调用的接口
          this.generateAnnualReport2(fileArr);
        })
        .catch(error => {
          this.$emit("reportComLoad");
        });
    },
    captureAndUploadSingle(ref, item) {
      return new Promise((resolve, reject) => {
        html2canvas(ref)
          .then(canvas => {
            canvas.toBlob(blob => {
              const file = new File([blob], item.fileName + ".png", {
                type: "image/png"
              });
              resolve(file);
            }, "image/png");
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    generateAnnualReport2(files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      generateAnnualReport2(formData, this.magId, this.year)
        .then(res => {
          if (res.data.status === 0) {
            this.$message.success(res.data.message);
            this.$emit("reportComSuccess");
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(error => {});
    },
    formatPercentage(pushSuccessCount, pushAuthorCount) {
      // 计算百分比
      let result = (pushSuccessCount / pushAuthorCount) * 100;
      // 根据小数部分决定保留的位数
      return result % 1 === 0 ? result.toFixed(0) : result.toFixed(1) + "%";
    },
    // 图8 9 10
    getTableList(chooseType, id) {
      return new Promise((resolve, reject) => {
        let params = {
          page: 0,
          size: 999,
          sort: "pushTime,desc",
          status: 3,
          magId: this.magId,
          type: chooseType || "",
          year: this.year
        };
        PushTaskList(params).then(res => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data;
            // 为每个数据项计算序号
            if (id) {
              this.comList.forEach(item => {
                if (item.id === id) {
                  item.data = data.content.map((item, index) => ({
                    ...item,
                    pushSuccessPercent: this.formatPercentage(
                      item.pushSuccessCount,
                      item.pushAuthorCount
                    )
                  }));
                }
              });
            }
            // 查询全部的时候获取
            if (!chooseType) {
              let MaxEmailOpenPercentObj = this.getMaxEmailOpenPercent(
                data.content
              );
              if (MaxEmailOpenPercentObj) {
                this.GetHistoryPushSetList(MaxEmailOpenPercentObj, 13);
              }
            }
          } else {
            const { message = "" } = res.data;
            this.$message.error(message);
          }
          resolve();
        });
      });
    },
    // 图13
    GetHistoryPushSetList(MaxEmailOpenPercentObj, id) {
      let params = {
        historyId: MaxEmailOpenPercentObj.id,
        page: 0,
        size: 999,
        sort: ""
      };
      GetHistoryPushSetList(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data;
          this.comList.forEach(item => {
            if (item.id === id) {
              item.data = data.filter(
                it => it.successCount && it.openAuthorCount
              );
            }
          });
        } else {
          const { message = "" } = res.data;
          this.$message.error(message);
        }
      });
    },
    // 图35数据
    getGetCitationGraph(id) {
      return new Promise((resolve, reject) => {
        let params = {
          magId: this.magId,
          page: 0,
          size: 10,
          sort: "totalCount5,desc",
          country: "中国"
        };
        GetCitationGraph(params).then(res => {
          const { data } = res.data;
          let list = data.map(item => {
            return {
              name: item.ctiMagName + "，" + item.totalCount5,
              value: item.totalCount5
            };
          });
          circularChart34(id, list);
          resolve();
        });
      });
    },
    // 图34数
    getGetQuoteGraph(id) {
      return new Promise((resolve, reject) => {
        let params = {
          magId: this.magId,
          page: 0,
          size: 10,
          sort: "totalCount5,desc",
          country: "中国"
        };
        GetQuoteGraph(params).then(res => {
          const { data } = res.data;
          let list = data.map(item => {
            return {
              name: item.refMagName + "，" + item.totalCount5,
              value: item.totalCount5
            };
          });
          circularChart34(id, list);
          resolve();
        });
      });
    },
    // 图24数据
    async getData(id) {
      return new Promise(async (resolve, reject) => {
        let params1 = {
          page: 0,
          size: 999,
          sort: "pushTime,desc",
          status: 3,
          magId: this.magId,
          type: "",
          year: this.year
        };
        let params2 = {
          ...params1,
          year: this.year - 1
        };
        Promise.all([PushTaskList(params1), PushTaskList(params2)]).then(
          res => {
            let data = res.map(item => {
              return item.data.data.content.sort((a, b) => a.issue - b.issue);
            });
            let arr = data.map(item => {
              return item.map(it => {
                return it.emailOPenCount;
              });
            });
            let longerArray =
              arr[0].length >= arr[1].length ? data[0] : data[1];
            let nameArr = longerArray.map(item => {
              return item.issue;
            });
            lineChart24(id, arr[0], nameArr, arr[1], this.year);
          }
        );
        resolve();
      });
    },
    // 图22数据
    async getCountRootMagOrg(id, apiMethod, domId) {
      return new Promise((resolve, reject) => {
        let params = {
          magId: this.magId,
          startTime: this.year + "-01",
          endTime: this.year + "-12",
          page: 0,
          size: 10
        };
        if (id === 41) {
          params.size = 50; // 前50条
          params.startTime = this.year - 2 + "-01-01"; // 取近3年数据
        }
        apiMethod(params).then(res => {
          if (res && res.data && res.data.status === 0) {
            let content = res.data.data.content;
            let key = "";
            let title = "";
            // 表格
            if (id === 41) {
              this.comList.forEach(item => {
                if (item.id === id) {
                  item.data = content.map(item => {
                    return {
                      ...item,
                      platMagName: this.platMagName
                    };
                  });
                }
              });
            } else if (id === 22 || id === 23) {
              content = content.reverse();
              // 条形图
              if (id === 22) {
                key = "INSTITUTION";
                title = this.year + "年度发文机构被引次数排名";
              } else if (id === 23) {
                key = "CITATIONS_MAG";
                title = this.year + "年度引证期刊引证次数排名";
              }
              let xData = content.map(item => {
                return item[key];
              });
              let data = content.map(item => {
                return item.frequency;
              });
              colChart22(domId, title, xData, data);
            }
          }
          resolve();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReportCom {
  width: 966px;
  margin: 0 auto;
  background: #f5f7fd;
  padding: 20px;
  &_item {
    margin-bottom: 20px;
    padding: 20px;
    background: #fff;
    h2 {
      margin-bottom: 10px;
    }
  }
}
.echartsStyle {
  width: 100%;
  height: 300px;
}
.echartsStyle4 {
  width: 100%;
  height: 400px;
}
</style>
