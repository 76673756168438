<template>
  <div class="TotalCom">
    <div class="part1-content" v-if="totalNumDataInfo">
      <div class="part1-content-top flex">
        <div class="content1">
          <img
            class="mag-img"
            src="@/assets/img/analysisReport/img1.png"
            alt=""
          />
          <div class="numBox">
            <p>
              本刊发表文献<span>{{ totalNumDataInfo.magArticleCount }}</span
              >篇，其中<span>{{ totalNumDataInfo.count }}</span
              >篇共被<span>{{ totalNumDataInfo.citationsCount }}</span
              >篇文献引用
            </p>
          </div>
          <img
            class="arrow-down"
            src="@/assets/img/analysisReport/arrow-down.png"
            alt=""
          />
        </div>
        <img
          class="arrow-right"
          src="@/assets/img/analysisReport/arrow-right.png"
          alt=""
        />
        <div class="content2">
          <div
            id="chartYZWX"
            :style="{
              width: '115px',
              height: '115px'
            }"
          />
          <div class="numBox">
            <p
              v-for="(item,
              index) in totalNumDataInfo.citationsArticleCountGroupByType"
              :key="index"
            >
              <span class="dot" :style="{ background: colorArr[index] }"></span
              >{{ item.type }}：<span>{{ item.count }}</span
              >篇
            </p>
          </div>
          <img
            class="arrow-down"
            src="@/assets/img/analysisReport/arrow-down.png"
            alt=""
          />
        </div>
        <img
          class="arrow-right"
          src="@/assets/img/analysisReport/arrow-right.png"
          alt=""
        />
        <div class="content3">
          <div
            id="chartQKWX"
            :style="{
              width: '115px',
              height: '115px'
            }"
          />
          <div class="numBox">
            <p>
              引证文献中发表在学术期刊上的共<span>{{
                totalNumDataInfo.citationsMagArticleCount
              }}</span
              >篇
            </p>
          </div>
          <img
            class="arrow-down"
            src="@/assets/img/analysisReport/arrow-down.png"
            alt=""
          />
        </div>
      </div>
      <div class="part1-content-bottom flex">
        <div class="content1">
          <div
            id="chartXZFG"
            :style="{
              width: '115px',
              height: '115px'
            }"
          />
          <div class="numBox">
            <p>
              本刊发表文献中的<span>{{
                totalNumDataInfo.magAuthorArticleCount
              }}</span
              >篇文献作者在方正学者库中，占比<span>{{ rate1 }}</span>
            </p>
          </div>
        </div>
        <div class="content2">
          <div
            id="chartYZWX2"
            :style="{
              width: '115px',
              height: '115px'
            }"
          />
          <div class="numBox">
            <p>
              引证文献中的<span>{{
                totalNumDataInfo.citationsAuthorArticleCount
              }}</span
              >篇文献作者在方正学者库中，占比<span>{{ rate2 }}</span>
            </p>
          </div>
        </div>
        <div class="content3">
          <div
            id="chartXZFG2"
            :style="{
              width: '115px',
              height: '115px'
            }"
          />
          <div class="numBox">
            <p>
              期刊文献中的<span>{{
                totalNumDataInfo.citationsMagAuthorArticleCount
              }}</span
              >篇文献作者在方正学者库中，占比<span>{{ rate3 }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GetSumIssueArticleCitation } from "@/api/emailPush";
export default {
  props: {
    year: {
      type: String,
      default: ""
    }
  },
  name: "TotalCom",
  data() {
    return {
      totalNumDataInfo: null,
      colorArr: [
        "#5470C6 ",
        "#91CC75",
        "#FAC858",
        "#EE6666",
        "#37CBCB",
        "#90C340",
        "#F6B1B4",
        "#FC8452"
      ]
    };
  },
  computed: {
    ...mapGetters({
      magId: "magId"
    }),
    rate1() {
      if (!this.totalNumDataInfo.magAuthorArticleCount) {
        return "0%";
      }
      return (
        (
          (this.totalNumDataInfo.magAuthorArticleCount /
            this.totalNumDataInfo.magArticleCount) *
          100
        ).toFixed(2) + "%"
      );
    },
    rate2() {
      if (!this.totalNumDataInfo.citationsAuthorArticleCount) {
        return "0%";
      }
      return (
        (
          (this.totalNumDataInfo.citationsAuthorArticleCount /
            this.totalNumDataInfo.citationsCount) *
          100
        ).toFixed(2) + "%"
      );
    },
    rate3() {
      if (!this.totalNumDataInfo.citationsMagAuthorArticleCount) {
        return "0%";
      }
      return (
        (
          (this.totalNumDataInfo.citationsMagAuthorArticleCount /
            this.totalNumDataInfo.citationsMagArticleCount) *
          100
        ).toFixed(2) + "%"
      );
    }
  },
  watch: {
    year: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.getSumIssueArticleCitation();
        }
      },
      deep: true
    },
    magId(nv) {
      if (nv) {
        // this.initData();
      }
    }
  },
  created() {},
  methods: {
    getSumIssueArticleCitation() {
      let params = {
        magId: this.magId,
        startTime: this.year + "-01",
        endTime: this.year + "-12"
      };
      GetSumIssueArticleCitation(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data;
          this.totalNumDataInfo = data;
          this.$nextTick(() => {
            this.chartYZWX(data);
            this.chartQKWX(data);
            this.chartXZFG(data);
            this.chartYZWX2(data);
            this.chartXZFG2(data);
          });
        }
      });
    },
    chartYZWX(data) {
      console.log(
        document.getElementById("chartYZWX"),
        "document.getElementById()"
      );
      if (!document.getElementById("chartYZWX")) {
        return;
      }
      var dataArr = data.citationsArticleCountGroupByType;
      if (dataArr && dataArr.length > 0) {
        dataArr.map(item => {
          item.name = item.type;
          item.value = item.count;
        });
      }
      let _this = this;
      const myChart = this.$echarts.init(document.getElementById("chartYZWX"));
      myChart.setOption(
        {
          // tooltip: {
          //   trigger: 'item',
          //   formatter: '{a} <br/>{b}: {c}篇 ({d}%)'
          // },
          color: _this.colorArr,
          title: {
            text: _this.totalNumDataInfo.citationsCount + "篇",
            left: "center",
            top: "50%",
            textStyle: {
              color: "#4054AF",
              fontSize: 16,
              align: "center",
              fontWeight: 500
            }
          },
          graphic: {
            type: "text",
            left: "center",
            top: "40%",
            style: {
              text: "引证文献",
              textAlign: "center",
              fill: "#999999",
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: "引证文献",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "left"
              },
              hoverAnimation: false,
              data: dataArr
            }
          ]
        },
        true
      );
    },
    chartQKWX(data) {
      if (!document.getElementById("chartQKWX")) {
        return;
      }
      const myChart = this.$echarts.init(document.getElementById("chartQKWX"));
      myChart.setOption(
        {
          color: "#5470C6",
          title: {
            text: data.citationsMagArticleCount + "篇",
            left: "center",
            top: "50%",
            textStyle: {
              color: "#4054AF",
              fontSize: 16,
              align: "center",
              fontWeight: 500
            }
          },
          graphic: {
            type: "text",
            left: "center",
            top: "40%",
            style: {
              text: "期刊文献",
              textAlign: "center",
              fill: "#999999",
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: "期刊文献",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "left"
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.citationsMagArticleCount
                }
              ]
            }
          ]
        },
        true
      );
    },
    chartXZFG(data) {
      if (!document.getElementById("chartXZFG")) {
        return;
      }
      const myChart = this.$echarts.init(document.getElementById("chartXZFG"));
      myChart.setOption(
        {
          color: ["#37CBCB", "#E5E8F0"],
          title: {
            text: data.magAuthorArticleCount + "篇",
            left: "center",
            top: "50%",
            textStyle: {
              color: "#4054AF",
              fontSize: 16,
              align: "center",
              fontWeight: 500
            }
          },
          graphic: {
            type: "text",
            left: "center",
            top: "40%",
            style: {
              text: "学者覆盖",
              textAlign: "center",
              fill: "#999999",
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: "学者覆盖",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "left"
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.magArticleCount
                },
                { value: data.magArticleCount - data.magAuthorArticleCount }
              ]
            }
          ]
        },
        true
      );
    },
    chartYZWX2(data) {
      if (!document.getElementById("chartYZWX2")) {
        return;
      }
      const myChart = this.$echarts.init(document.getElementById("chartYZWX2"));
      myChart.setOption(
        {
          color: ["#37CBCB", "#E5E8F0"],
          title: {
            text: data.citationsAuthorArticleCount + "篇",
            left: "center",
            top: "50%",
            textStyle: {
              color: "#4054AF",
              fontSize: 16,
              align: "center",
              fontWeight: 500
            }
          },
          graphic: {
            type: "text",
            left: "center",
            top: "40%",
            style: {
              text: "学者覆盖",
              textAlign: "center",
              fill: "#999999",
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: "学者覆盖",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "left"
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.citationsAuthorArticleCount
                },
                {
                  value: data.citationsCount - data.citationsAuthorArticleCount
                }
              ]
            }
          ]
        },
        true
      );
    },
    chartXZFG2(data) {
      if (!document.getElementById("chartXZFG2")) {
        return;
      }
      const myChart = this.$echarts.init(document.getElementById("chartXZFG2"));
      myChart.setOption(
        {
          color: ["#37CBCB", "#E5E8F0"],
          title: {
            text: data.citationsMagAuthorArticleCount + "篇",
            left: "center",
            top: "50%",
            textStyle: {
              color: "#4054AF",
              fontSize: 16,
              align: "center",
              fontWeight: 500
            }
          },
          graphic: {
            type: "text",
            left: "center",
            top: "40%",
            style: {
              text: "学者覆盖",
              textAlign: "center",
              fill: "#999999",
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: "学者覆盖",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "left"
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.citationsMagAuthorArticleCount
                },
                {
                  value:
                    data.citationsMagArticleCount -
                    data.citationsMagAuthorArticleCount
                }
              ]
            }
          ]
        },
        true
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.TotalCom {
  .part1-content {
    margin-top: 30px;
    background: #f5f7fd;
    padding: 30px;
    .flex {
      display: flex;
      align-items: center;
    }
    .mag-img {
      width: 115px;
      height: 76px;
    }
    .arrow-right {
      width: 54px;
      height: 19px;
      margin: 0 20px;
    }
    .arrow-down {
      width: 19px;
      height: 28px;
    }
    .part1-content-top,
    .part1-content-bottom {
      .content1,
      .content2,
      .content3 {
        display: flex;
        width: 30%;
        align-items: center;
        text-align: left;
        position: relative;
        .numBox {
          text-align: left;
          margin-left: 10px;
          color: #666;
          font-size: 14px;
          flex: 1;
          .dot {
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 4px;
          }
          span {
            color: #4054af;
          }
        }
      }
      .content1 {
        .arrow-down {
          position: absolute;
          bottom: -54px;
          left: 50px;
        }
      }
      .content2,
      .content3 {
        .arrow-down {
          position: absolute;
          bottom: -40px;
          left: 50px;
        }
      }
    }
    .part1-content-bottom {
      margin-top: 50px;
      justify-content: space-between;
      .content1,
      .content2,
      .content3 {
        width: 26%;
      }
    }
  }
}
</style>
