var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TotalCom"},[(_vm.totalNumDataInfo)?_c('div',{staticClass:"part1-content"},[_c('div',{staticClass:"part1-content-top flex"},[_c('div',{staticClass:"content1"},[_c('img',{staticClass:"mag-img",attrs:{"src":require("@/assets/img/analysisReport/img1.png"),"alt":""}}),_c('div',{staticClass:"numBox"},[_c('p',[_vm._v(" 本刊发表文献"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.magArticleCount))]),_vm._v("篇，其中"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.count))]),_vm._v("篇共被"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.citationsCount))]),_vm._v("篇文献引用 ")])]),_c('img',{staticClass:"arrow-down",attrs:{"src":require("@/assets/img/analysisReport/arrow-down.png"),"alt":""}})]),_c('img',{staticClass:"arrow-right",attrs:{"src":require("@/assets/img/analysisReport/arrow-right.png"),"alt":""}}),_c('div',{staticClass:"content2"},[_c('div',{style:({
            width: '115px',
            height: '115px'
          }),attrs:{"id":"chartYZWX"}}),_c('div',{staticClass:"numBox"},_vm._l((_vm.totalNumDataInfo.citationsArticleCountGroupByType),function(item,index){return _c('p',{key:index},[_c('span',{staticClass:"dot",style:({ background: _vm.colorArr[index] })}),_vm._v(_vm._s(item.type)+"："),_c('span',[_vm._v(_vm._s(item.count))]),_vm._v("篇 ")])}),0),_c('img',{staticClass:"arrow-down",attrs:{"src":require("@/assets/img/analysisReport/arrow-down.png"),"alt":""}})]),_c('img',{staticClass:"arrow-right",attrs:{"src":require("@/assets/img/analysisReport/arrow-right.png"),"alt":""}}),_c('div',{staticClass:"content3"},[_c('div',{style:({
            width: '115px',
            height: '115px'
          }),attrs:{"id":"chartQKWX"}}),_c('div',{staticClass:"numBox"},[_c('p',[_vm._v(" 引证文献中发表在学术期刊上的共"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.citationsMagArticleCount))]),_vm._v("篇 ")])]),_c('img',{staticClass:"arrow-down",attrs:{"src":require("@/assets/img/analysisReport/arrow-down.png"),"alt":""}})])]),_c('div',{staticClass:"part1-content-bottom flex"},[_c('div',{staticClass:"content1"},[_c('div',{style:({
            width: '115px',
            height: '115px'
          }),attrs:{"id":"chartXZFG"}}),_c('div',{staticClass:"numBox"},[_c('p',[_vm._v(" 本刊发表文献中的"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.magAuthorArticleCount))]),_vm._v("篇文献作者在方正学者库中，占比"),_c('span',[_vm._v(_vm._s(_vm.rate1))])])])]),_c('div',{staticClass:"content2"},[_c('div',{style:({
            width: '115px',
            height: '115px'
          }),attrs:{"id":"chartYZWX2"}}),_c('div',{staticClass:"numBox"},[_c('p',[_vm._v(" 引证文献中的"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.citationsAuthorArticleCount))]),_vm._v("篇文献作者在方正学者库中，占比"),_c('span',[_vm._v(_vm._s(_vm.rate2))])])])]),_c('div',{staticClass:"content3"},[_c('div',{style:({
            width: '115px',
            height: '115px'
          }),attrs:{"id":"chartXZFG2"}}),_c('div',{staticClass:"numBox"},[_c('p',[_vm._v(" 期刊文献中的"),_c('span',[_vm._v(_vm._s(_vm.totalNumDataInfo.citationsMagAuthorArticleCount))]),_vm._v("篇文献作者在方正学者库中，占比"),_c('span',[_vm._v(_vm._s(_vm.rate3))])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }