/*
  label: 列的名称
  prop: 对应数据中的字段名
  sortable: 是否可排序
  slotName: 自定义插槽
*/
// 图 8 9 10
import * as echarts from "echarts";
const column8 = [
  {
    label: "推送期刊",
    prop: "topic",
    width: 200
  },
  {
    label: "推送目标数量",
    prop: "pushAuthorCount",
    width: 120
  },
  {
    label: "推送成功数量",
    prop: "pushSuccessCount",
    width: 120
  },
  {
    label: "送达率",
    prop: "pushSuccessPercent",
    width: 80
  },
  {
    label: "打开数",
    prop: "emailOPenCount",
    width: 80
  },
  {
    label: "打开率",
    prop: "emailOPenPercent",
    width: 80
  },
  {
    label: "推送时间",
    prop: "pushTime"
  }
];
// 图13
const column13 = [
  {
    label: "学者来源",
    prop: "sourceName"
  },
  {
    label: "邮件推送量",
    prop: "successCount"
  },
  {
    label: "邮件阅读量",
    prop: "openAuthorCount"
  }
];
// 图24
const lineChart24 = function(id, data, name, arr, year) {
  name = name.map(item => {
    return item + "期";
  });
  var chartDom = document.getElementById(id);
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // title: {
    //   text: "Stacked Line"
    // },
    tooltip: {
      trigger: "item"
    },

    legend: {
      data: [`${year}年邮件阅读次数`, `${Number(year) - 1}年邮件阅读次数`]
    },
    legend: {
      // Try 'horizontal'
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "category",
      data: name,
      boundaryGap: true //双侧留白
    },
    yAxis: {
      type: "value",
      axisLine: { show: false } // 隐藏 y 轴线,
    },
    series: [
      {
        name: `${year}年邮件阅读次数`,
        type: "line",
        data: data,
        itemStyle: { normal: { label: { show: true } } }
      },
      {
        name: `${Number(year) - 1}年邮件阅读次数`,
        type: "line",
        data: arr,
        itemStyle: {
          normal: {
            label: {
              show: true
            }
          }
        }
      }
    ]
  };

  option && myChart.setOption(option);
};
// 图34 35
const circularChart34 = function(id, data) {
  var chartDom = document.getElementById(id);
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    tooltip: {
      trigger: "item"
    },
    legend: {
      orient: "vertical",
      x: "right",
      y: "bottom",
      data: ["在线", "离线"]
    },
    series: [
      {
        // name: "来源",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        data: data
      }
    ]
  };

  option && myChart.setOption(option);
};
// 图22 23
const colChart22 = function(id, title, yData, data) {
  var chartDom = document.getElementById(id);
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: title,
      textStyle: {
        color: '#4874CB'  // 设置标题的颜色为 #4874CB
      },
      x: 'center',  // 设置标题居中
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: yData,
      axisLabel: {
        formatter: function (value) {
          return value.length > 15 ? value.substring(0, 15) + '...' : value;
        }
      }
    },
    series: [
      {
        name: '被引次数',
        type: 'bar',
        data: data,
        itemStyle: {
          color: '#4874CB'  // 设置每一项的颜色为 #4874CB
        },
        label: {
          show: true,
          position: 'right'
        },
        barCategoryGap: '30%',  // 设置条形图不同类别之间的间距
        barGap: '10%'  // 设置同一类别内部条形之间的间距
      }
    ]
  };
  option && myChart.setOption(option);
}
// 图41
const column41 = [
  {
    label: "期刊名称",
    prop: "platMagName"
  },
  {
    label: "年份",
    prop: "year"
  },
  {
    label: "刊期",
    prop: "magNumber"
  },
  {
    label: "文章标题",
    prop: "root_title",
    width: 350
  },
  {
    label: "被引次数",
    prop: "frequency"
  }
];
export { column8, column13, lineChart24, circularChart34, colChart22, column41 };
