<template>
  <div>
    <el-table
      ref="table"
      :data="tableData"
      @sort-change="handleSortChange"
      stripe
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      :border="border"
      :row-class-name="rowClassName"
    >
      <el-table-column v-if="showIndex" type="index" :label="'序号'" width="50">
      </el-table-column>
      <template v-for="(column, index) in columns">
        <el-table-column
          v-if="column.slotName"
          :key="index"
          :prop="column.prop"
          :label="column.label"
          :width="column.width || 'auto'"
          :min-width="column.width || 'auto'"
          align="center"
        >
          <template slot-scope="scope">
            <slot :name="column.slotName" :data="scope.row"></slot>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable"
          :width="column.width || 'auto'"
          :min-width="column.width || 'auto'"
        >
          <template v-slot:default="{ row }">
            <span
              :title="filterHtml(row[column.prop])"
              v-html="row[column.prop]"
            ></span>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "CommonTable",
  props: {
    tableData: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    cellHeight: {
      type: Number,
      default: 30
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    tableData: {
      handler() {
        if (this.$refs.table) {
          this.$refs.table.doLayout();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 动态为每一行添加class
    rowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? "even-row" : "odd-row";
    },
    filterHtml(str) {
      return str ? str.toString().replace(/<[^>]+>/g, "") : "";
    },
    // 排序事件
    handleSortChange({ column, prop, order }) {
      this.$emit("sortChangeEvent", { column, prop, order });
    },
    cellStyle() {
      // 返回单元格的通用样式
      return {
        "line-height": this.cellHeight + "px !important"
      };
    },
    headerCellStyle() {
      return {
        "line-height": this.cellHeight + "px !important",
        height: this.cellHeight + "px !important"
      };
    },
    resetSort() {
      // 使用 $refs 获取 el-table 实例，并调用 clearSort 方法清除排序状态
      this.$refs.table.clearSort();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .cell,
::v-deep .el-table .el-table__header thead th .cell {
  font-size: 14px;
  line-height: unset;
  height: unset;
  text-overflow: ellipsis;
  text-align: center;
}
::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: transparent;
}
::v-deep .el-table .el-table__header thead th {
  height: unset !important;
}
::v-deep .el-table {
  text-align: center;
  th.el-table__cell {
    background-color: #d9e1f4;
  }
  .cell {
    color: #000 !important;
  }
  .even-row {
    background-color: #fff;
  }
  .odd-row {
    background-color: #d9e1f4;
  }
}
</style>
